import React from "react";
import { getDrivers } from "../../../../../../api/CompanyAPI";
import { useState, useEffect } from "react";
import Select from "react-select";

const DriverSelect = ({ user, currentDriver, onDriverChange }) => {
  let loadedData = false;
  const [driver, setDriver] = useState([]);
  const [drivers, setDrivers] = useState([
    { value: "driver1", label: "Driver 1" },
  ]);

  useEffect(() => {
    if (!loadedData) {
      loadedData = true;
      fetchDrivers();
    }
  }, []);

  const fetchDrivers = async () => {
    // Fetch drivers data from the API

    try {
      const fetchedDrivers = await getDrivers(
        user.currentCompanyRole.company._id
      ); // need to send the company id

      const drivers = fetchedDrivers.map((driver) => ({
        value: driver.user._id,
        label: `${driver.user.firstName} ${driver.user.lastName}`,
      }));

      setDrivers(drivers);
      setDriver(drivers.find((driver) => driver.value === currentDriver));
    } catch (error) {
      console.error("Failed to fetch units", error);
    }
  };

  const handleDriverChange = (selectedOptions) => {
    setDriver(selectedOptions);
    onDriverChange(selectedOptions);
  };

  return (
    <div className="mb-4">
      <label className="block mb-1">Driver</label>
      <Select
        name="drivers"
        options={drivers}
        className="basic-multi-select"
        classNamePrefix="select"
        value={driver}
        onChange={handleDriverChange}
      />
    </div>
  );
};

export default DriverSelect;
