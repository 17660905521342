import React, { useState, useEffect } from "react";
import axiosInstance from "./../../../../../../api/axiosInstance";
import { FaClipboardCheck, FaCamera, FaEdit, FaTrash } from "react-icons/fa";
import ImageUploading from "react-images-uploading";
import { useParams } from "react-router-dom";

export default function Pictures({
  companyId,
  inspectionId,
  onNewPhotoAdded,
  onUploadProgress,
}) {
  const [images, setImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const maxNumber = 69;
  const { unitId } = useParams(); // Get unitId from the URL

  const onChange = (imageList) => {
    setImages(imageList);
  };

  const handleUpload = async () => {
    setUploadError(null);
    setIsLoading(true);
    const formData = new FormData();

    if (images.length === 0) {
      console.error("No images to upload");
      setIsLoading(false);
      return;
    }

    images.forEach((image, index) => {
      formData.append("files", image.file);
    });
    formData.append("type", "inspection");
    formData.append("company_id", companyId);
    formData.append("inspection_id", inspectionId);

    try {
      const response = await axiosInstance.post(
        "/inspection/photo-inspection/" + unitId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            onUploadProgress(percentCompleted);
            console.log("Upload progress:", percentCompleted);
          },
        }
      );

      setImages([]);
      setUploadProgress(0);
      setUploadError(null);
      setIsLoading(false);

      onNewPhotoAdded(response.data);
    } catch (err) {
      console.error("Error uploading pictures:", err);
      setUploadError(
        "Error uploading pictures. Please check your network and try again."
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      handleUpload();
    }
  }, [images]);

  return (
    <div className="flex flex-col space-y-4">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="flex flex-col items-center">
            <div className="relative">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-2xl font-semibold text-white">
                  {uploadProgress}%
                </span>
              </div>
            </div>
            <h2 className="text-xl font-semibold text-white">Uploading...</h2>
            <p className="w-1/3 text-center text-white">Please wait...</p>
          </div>
        </div>
      )}
      {/* <div className="flex justify-center items-center space-x-4">
        <FaClipboardCheck className="text-4xl text-green-500" />
        <div>
          <h1 className="text-2xl font-semibold">Photo Inspection</h1>
          <p className="text-gray-500">Upload photo inspection for the unit</p>
        </div>
      </div> */}

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
              <button
                className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <FaCamera className="mr-2" />
                Add pictures
              </button>
              {images.length > 0 && (
                <button
                  className="flex items-center justify-center px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                  onClick={onImageRemoveAll}
                >
                  Remove all images
                </button>
              )}
            </div>
            <div className="flex flex-wrap justify-center items-center space-x-4">
              {imageList.map((image, index) => (
                <div key={index} className="relative image-item m-2">
                  <img
                    src={image["data_url"]}
                    alt=""
                    className="w-24 h-24 object-cover rounded-md"
                  />
                  <div className="absolute top-0 right-0 flex space-x-1 mt-1 mr-1">
                    <button
                      className="p-1 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                      onClick={() => onImageUpdate(index)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="p-1 bg-red-500 text-white rounded-full shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                      onClick={() => onImageRemove(index)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>

      {uploadError && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="flex flex-col items-center">
            <span className="text-xl text-white font-bold mb-4 p-2">
              {uploadError}
            </span>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
              onClick={handleUpload}
            >
              Retry
            </button>
          </div>
        </div>
      )}
      {images.length > 0 && !uploadError && (
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          onClick={handleUpload}
        >
          Upload to Cloud
        </button>
      )}
    </div>
  );
}
