import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import Header from "../../../../components/Header";
import PreInspectionsTab from "./components/PreInspectionsTab";
import InspectionsTab from "./components/InspectionsTab";
import DriverPreInspectionComponent from "./../PreInspections/PreInspections";
import { FaClipboardList, FaClipboardCheck } from "react-icons/fa";

export default function Units() {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [activeTab, setActiveTab] = useState("inspection");
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      setUserRole(user.currentCompanyRole.role.name);
    }
  }, [user]);

  const renderContent = () => {
    if (userRole === "owner") {
      return (
        <div className="w-full">
          <div className="tabs flex justify-around mb-4">
            <button
              className={`tab flex items-center justify-center w-full py-2 rounded-tl-lg ${
                activeTab === "inspection"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setActiveTab("inspection")}
            >
              <FaClipboardList className="mr-2" />
              Inspections
            </button>
            <button
              className={`tab flex items-center justify-center w-full py-2 rounded-tr-lg ${
                activeTab === "pre-inspection"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setActiveTab("pre-inspection")}
            >
              <FaClipboardCheck className="mr-2" />
              Pre-Inspection
            </button>
          </div>
          <div className="tab-content">
            {activeTab === "inspection" ? (
              <InspectionsTab />
            ) : (
              <PreInspectionsTab />
            )}
          </div>
        </div>
      );
    } else if (userRole === "driver") {
      return <DriverPreInspectionComponent />;
    } else {
      return (
        <div className="text-center">
          <p className="text-red-500">
            We don't have the unit inspection. Please contact your
            administrator!
          </p>
        </div>
      );
    }
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <Header />
        <div className="container mx-auto md:p-4 pb-16">{renderContent()}</div>
      </div>
    </div>
  );
}
