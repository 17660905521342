import React, { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import Header from "../../../components/Header";
import UnitDetails from "./components/ownerComponents/UnitDetails";
import DriverUnitDetails from "./components/driverComponents/UnitDetails";

export default function Units() {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      setUserRole(user.currentCompanyRole.role.name);
    }
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="container mx-auto md:p-4 pb-16 flex-1">
        {userRole === "owner" ? (
          <UnitDetails />
        ) : userRole === "driver" ? (
          <DriverUnitDetails />
        ) : (
          <div className="text-center">
            <p className="text-red-500">
              We don't recognize this unit. Please contact your administrator!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
