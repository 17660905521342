import React, { useEffect } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import PreInspectionHistory from "./components/PreInspectionHistory";
import PreTripInspectionList from "./components/PreTripInspectionList";
import Header from "../../components/Header";

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.isNewUser) {
      navigate("/dashboard/new-user");
    }
  }, [user, navigate]);

  return (
    <div className="flex">
      <div className="flex-1">
        <Header />
        {/* <PreInspectionHistory /> */}
        <PreTripInspectionList />
      </div>
    </div>
  );
}
