import React from "react";
import { FaClipboardList, FaCar, FaFileAlt } from "react-icons/fa";
import { Button } from "./../../../../../components/Button";
import { useParams } from "react-router-dom";

const UnitDetails = () => {
  const { unitId } = useParams();

  return (
    <div className="flex flex-col items-center space-y-6">
      {/* ToDo: All buttons need to be like this one below  */}
      <Button
        className="flex items-center justify-center w-64 h-16"
        href="#"
        to={`/dashboard/unit/${unitId}/inspections`}
      >
        <FaClipboardList className="mr-2" />
        Inspections
      </Button>
      <div className="relative w-64 h-16">
        <Button
          className="flex items-center justify-center w-full h-full bg-blue-500 text-white text-lg rounded opacity-50 cursor-not-allowed"
          disabled
        >
          <FaCar className="mr-2" />
          Drivers
        </Button>
        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
          Soon
        </span>
      </div>
      <div className="relative w-64 h-16">
        <Button
          className="flex items-center justify-center w-full h-full bg-blue-500 text-white text-lg rounded opacity-50 cursor-not-allowed"
          disabled
        >
          <FaFileAlt className="mr-2" />
          Documents
        </Button>
        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
          Soon
        </span>
      </div>
    </div>
  );
};

export default UnitDetails;
