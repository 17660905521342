import React, { useEffect, useState } from "react";
import { getDrivers } from "../../../../api/CompanyAPI";
import { useAuth } from "../../../../AuthContext";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";

export default function DriverList() {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [copiedPhone, setCopiedPhone] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      fetchDrivers();
    }
  }, [user]);

  const fetchDrivers = async () => {
    try {
      const fetchedDrivers = await getDrivers(
        user.currentCompanyRole.company._id
      );
      const transformedDrivers = fetchedDrivers.map((driver) => ({
        id: driver._id,
        fullName: `${driver.user.firstName} ${driver.user.lastName}`,
        phone: driver.user.phone,
        email: driver.user.email,
      }));
      setDrivers(transformedDrivers);
    } catch (error) {
      console.error("Failed to fetch drivers", error);
    }
  };

  const filteredUsers = drivers.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePhoneClick = (phone) => {
    if (/Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)) {
      window.location.href = `tel:${phone}`;
    } else {
      navigator.clipboard.writeText(phone);
      setCopiedPhone(true);
      setTimeout(() => setCopiedPhone(false), 2000);
    }
  };

  const handleEmailClick = (email) => {
    if (/Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)) {
      window.location.href = `mailto:${email}`;
    } else {
      navigator.clipboard.writeText(email);
      setCopiedEmail(true);
      setTimeout(() => setCopiedEmail(false), 2000);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search users..."
          className="border p-2 rounded mb-2 md:mb-0"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto">
        <div className="hidden md:block">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border">Full Name</th>
                <th className="py-2 px-4 border">Phone</th>
                <th className="py-2 px-4 border">Email</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length === 0 ? (
                <tr>
                  <td colSpan="3" className="py-2 px-4 border text-center">
                    No Drivers
                  </td>
                </tr>
              ) : (
                filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="py-2 px-4 border">{user.fullName}</td>
                    <td
                      className="py-2 px-4"
                      onClick={() => handlePhoneClick(user.phone)}
                    >
                      <div className="flex items-center ">
                        {" "}
                        <FaPhone className="mr-2" />
                        <span>{copiedPhone ? "Copied" : user.phone}</span>
                      </div>
                    </td>

                    <td
                      className="py-2 px-4 border "
                      onClick={() => handleEmailClick(user.email)}
                    >
                      <div className="flex items-center ">
                        <FaEnvelope className="mr-2" />
                        <span>{copiedEmail ? "Copied" : user.email}</span>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="block md:hidden">
          {filteredUsers.length === 0 ? (
            <div className="text-center py-4">No Drivers</div>
          ) : (
            filteredUsers.map((user) => (
              <div
                key={user.id}
                className="flex-1 bg-gray-100 p-4 rounded-lg shadow-md mb-4 md:mb-0 w-full"
              >
                <div className="flex items-center mb-2">
                  <FaUser className="text-gray-600 mr-2" />
                  <p className="font-semibold text-lg">{user.fullName}</p>
                </div>
                <div
                  className="flex items-center text-sm text-gray-600 mb-2 cursor-pointer"
                  onClick={() => handlePhoneClick(user.phone)}
                >
                  <FaPhone className="mr-2" />
                  <span>{copiedPhone ? "Copied" : user.phone}</span>
                </div>
                <div
                  className="flex items-center text-sm text-gray-600 cursor-pointer"
                  onClick={() => handleEmailClick(user.email)}
                >
                  <FaEnvelope className="mr-2" />
                  <span>{copiedEmail ? "Copied" : user.email}</span>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
