import React from "react";
import { FaTruck, FaTrailer, FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const PreTripInspectionList = () => {
  return (
    <div className="bg-white shadow-md rounded-lg m-6 p-4 flex flex-col items-center hover:bg-gray-100">
      <h2 className="text-xl font-bold mb-4">Pre-Trip Inspection</h2>
      <div className="mb-6 w-full">
        <h3 className="text-lg font-semibold flex items-center">
          <FaTruck className="mr-2" /> Truck Inspection
        </h3>
        <ul className="list-disc pl-6">
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check engine oil
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check tires
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check lights
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check brakes
          </li>
        </ul>
      </div>
      <div className="w-full pb-8">
        <h3 className="text-lg font-semibold flex items-center">
          <FaTrailer className="mr-2" /> Trailer Inspection
        </h3>
        <ul className="list-disc pl-6">
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check coupling
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check tires
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check lights
          </li>
          <li className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" /> Check brakes
          </li>
        </ul>
      </div>

      <Link
        to={"/dashboard/units"}
        className="inline-flex justify-center rounded-lg text-sm font-semibold  transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 flex items-center justify-center w-64 h-12"
      >
        Let's Start
      </Link>
    </div>
  );
};

export default PreTripInspectionList;
