import React, { useState, useEffect, useRef } from "react";
import {
  FaCamera,
  FaVideo,
  FaChevronRight,
  FaChevronDown,
  FaSpinner,
  FaRegCommentDots,
} from "react-icons/fa";
import { useAuth } from "./../../../../../AuthContext";
import { getPreInspections } from "./../../../../../api/preInspectionAPI";
import { getPictureDetails } from "./../../../../../api/picturesAPI"; // Import the function to fetch media details
import { getVideoDetails } from "./../../../../../api/videosAPI"; // Import the function to fetch media details
import { useParams } from "react-router-dom";
import moment from "moment";
import MediaModal from "./../../../../../components/MediaModal";

function PrevInspections() {
  const { user } = useAuth();
  const [selectedInspection, setSelectedInspection] = useState(null);
  const { unitId } = useParams();
  const [inspections, setInspections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const mediaCache = useRef({});

  let loadedData = false;
  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      // Fetch inspections for the unit
      getPreInspections(user.currentCompanyRole.company._id, unitId).then(
        (data) => setInspections(data.body)
      );
    }

    // Clear cache when component unmounts
    return () => {
      mediaCache.current = {};
    };
  }, [user, unitId]);

  const handleInspectionClick = async (index) => {
    setSelectedInspection(index === selectedInspection ? null : index);

    setInspections((prevInspections) => {
      const newInspections = [...prevInspections];
      newInspections[index] = { ...newInspections[index], loaded: false };
      return newInspections;
    });

    setInspections((prevInspections) => {
      const newInspections = [...prevInspections];
      newInspections[index] = {
        ...newInspections[index],
        loaded: true,
      };
      return newInspections;
    });
  };

  const handleMediaClick = async (mediaIds, type) => {
    if (loadingMedia) return; // Prevent multiple requests

    setLoadingMedia(true);

    const mediaDetails = await Promise.all(
      mediaIds.map(async (id) => {
        if (mediaCache.current[id]) {
          return mediaCache.current[id];
        }

        let media;
        if (type === "image") {
          media = await getPictureDetails(id);
        } else if (type === "video") {
          media = await getVideoDetails(id);
        }

        const mediaDetail = {
          type,
          src: media.signedUrl,
          fileName: media.fileName,
        };
        mediaCache.current[id] = mediaDetail;
        return mediaDetail;
      })
    );

    setCurrentMedia(mediaDetails);
    setCurrentIndex(0);
    setIsModalOpen(true);
    setLoadingMedia(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrevMedia = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : currentMedia.length - 1
    );
  };

  const handleNextMedia = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < currentMedia.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="mx-auto md:p-4 pb-16">
      <div className="flex flex-col space-y-4">
        {inspections.map((inspection, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden"
          >
            <div
              className="flex items-center justify-between p-4 bg-gray-100 hover:bg-gray-200 cursor-pointer transition-colors duration-200"
              onClick={() => handleInspectionClick(index)}
            >
              <span className="text-lg font-semibold">
                {moment(inspection.inspection_id.createdAt).format(
                  "MM-DD-YYYY HH:mm"
                )}
              </span>
              {selectedInspection === index ? (
                <FaChevronDown className="text-gray-600" />
              ) : (
                <FaChevronRight className="text-gray-600" />
              )}
            </div>
            {selectedInspection === index && (
              <div className="p-4 bg-gray-50">
                {inspection.loaded ? (
                  <>
                    <div className="flex flex-col md:flex-row items-start md:items-center mb-4">
                      <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                        <div className="flex-grow-0 flex-shrink-0 bg-gray-100 p-4 rounded-lg shadow-md mb-4 md:mb-0">
                          <div
                            className={`flex items-center mt-4 cursor-pointer border border-gray-300 p-2 rounded hover:bg-gray-100 transition-colors duration-200 ${
                              loadingMedia
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={() =>
                              inspection.inspection_id.picture_ids.length > 0 &&
                              handleMediaClick(
                                inspection.inspection_id.picture_ids,
                                "image"
                              )
                            }
                          >
                            <FaCamera className="text-gray-600 mr-2" />
                            <span>
                              {inspection.inspection_id.picture_ids.length}{" "}
                              Pictures
                            </span>
                          </div>
                          <div
                            className={`flex items-center mt-4 cursor-pointer border border-gray-300 p-2 rounded hover:bg-gray-100 transition-colors duration-200 ${
                              loadingMedia
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={() =>
                              inspection.inspection_id.video_ids.length > 0 &&
                              handleMediaClick(
                                inspection.inspection_id.video_ids,
                                "video"
                              )
                            }
                          >
                            <FaVideo className="text-gray-600 mr-2" />
                            <span>
                              {inspection.inspection_id.video_ids.length} Videos
                            </span>
                          </div>
                        </div>
                        <div className="flex-1 bg-gray-100 p-4 rounded-lg shadow-md w-full">
                          <div className="flex items-center text-gray-700">
                            <FaRegCommentDots className="text-gray-600 mr-2" />
                            {inspection.inspection_id.description ? (
                              <span>
                                {inspection.inspection_id.description}
                              </span>
                            ) : (
                              <span>No Description</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin text-gray-600 mr-2" />
                    <span>Loading...</span>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <MediaModal
        isOpen={isModalOpen}
        media={currentMedia}
        currentIndex={currentIndex}
        onClose={handleCloseModal}
        onPrev={handlePrevMedia}
        onNext={handleNextMedia}
        loading={loadingMedia}
      />
    </div>
  );
}

export default PrevInspections;
