import React, { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";

const ReviewSign = ({ onSignChange }) => {
  const sigPad = useRef(null);
  // const [isSaved, setIsSaved] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);

  const clear = () => {
    sigPad.current.clear();
    // setIsSaved(false);
    setHasSignature(false);
  };

  const save = () => {
    const dataURL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    onSignChange(dataURL);
    // setIsSaved(true);
  };

  const handleEnd = () => {
    setHasSignature(true);
    // setIsSaved(false);
    save();
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 bg-gray-100">
      <h2 className="mb-4 text-2xl font-bold text-center">Sign Below</h2>
      <div className="w-full max-w-lg p-4 bg-white border rounded shadow-md">
        <SignaturePad
          ref={sigPad}
          onEnd={handleEnd}
          canvasProps={{ className: "signatureCanvas w-full h-64 border" }}
        />
      </div>
      <div className="flex justify-between w-full max-w-lg mt-4">
        <button
          onClick={clear}
          className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
        >
          Clear
        </button>
        {/* {hasSignature && !isSaved && (
          <button
            onClick={save}
            className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
          >
            Save
          </button>
        )} */}
      </div>
    </div>
  );
};

export default ReviewSign;
