import React, { useEffect, useState } from "react";
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaTruck,
  FaTrailer,
  FaPhoneAlt,
} from "react-icons/fa";
import Select from "react-select";
import { Button } from "../../../../components/Button";
import {
  getUnits,
  createUnit,
  updateUnit,
  deleteUnit,
} from "../../../../api/unitAPI";
import { getDrivers } from "../../../../api/CompanyAPI";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function OwnerUnitsTable() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [units, setUnits] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [modalAction, setModalAction] = useState("create"); // create or edit
  const [unit, setUnit] = useState({
    type: "",
    unitNumber: "",
    vin: "",
    year: "",
    make: "",
    model: "",
    users: [],
  });
  const [drivers, setDrivers] = useState([
    { value: "driver1", label: "Driver 1" },
  ]);
  const [loadedUnits, setLoadedUnits] = useState(false);

  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;
      // Fetch units data from the API
      const fetchUnits = async () => {
        try {
          const fetchedUnits = await getUnits(
            user.currentCompanyRole.company._id
          ).then((data) => {
            setLoadedUnits(true);

            return data;
          });

          setUnits(fetchedUnits);
        } catch (error) {
          console.error("Failed to fetch units", error);
        }
      };

      fetchUnits();
    }
  }, [user, navigate]);

  const fetchDrivers = async () => {
    // Fetch drivers data from the API

    try {
      const fetchedDrivers = await getDrivers(
        user.currentCompanyRole.company._id
      ); // need to send the company id

      const drivers = fetchedDrivers.map((driver) => ({
        value: driver.user._id,
        label: `${driver.user.firstName} ${driver.user.lastName}`,
      }));

      setDrivers(drivers);
    } catch (error) {
      console.error("Failed to fetch units", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnit({ ...unit, [name]: value });
  };

  const handleDriverChange = (selectedOptions) => {
    setUnit({ ...unit, users: selectedOptions });
  };

  const handleCreateUnit = async () => {
    try {
      unit.company = user.currentCompanyRole.company._id;

      const createdUnit = await createUnit(unit);
      setUnits([...units, createdUnit]);
      setShowModal(false);
      setUnit({
        type: "",
        unitNumber: "",
        vin: "",
        year: "",
        make: "",
        model: "",
        users: [],
      });
    } catch (error) {
      console.error("Failed to create unit", error.message);
      alert("The current VIN is existing in the system");
    }
  };

  const handleUpdateUnit = async () => {
    try {
      const updatedUnit = await updateUnit(unit);

      // Find the index of the unit being updated
      const index = units.findIndex((u) => u._id === updatedUnit._id);

      // Create a new array with the updated unit
      const updatedUnits = [
        ...units.slice(0, index),
        updatedUnit,
        ...units.slice(index + 1),
      ];

      // Update the state with the new array
      setUnits(updatedUnits);

      setShowModal(false);
      setUnit({
        type: "",
        unitNumber: "",
        vin: "",
        year: "",
        make: "",
        model: "",
        users: [],
      });
    } catch (error) {
      console.error("Failed to update unit", error);
    }
  };

  const handleDeleteClick = async (unit) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this unit? All data will be deleted!"
    );

    if (!isConfirmed) {
      return; // Abort the deletion if the user cancels
    }

    try {
      const company_Id = user.currentCompanyRole.company._id;
      const unit_id = unit._id;

      await deleteUnit({ unit_id, company_Id });

      // Filter the units array to remove the deleted unit
      const updatedUnits = units.filter((u) => u._id !== unit._id);

      // Update the state with the new array
      setUnits(updatedUnits);
    } catch (error) {
      console.error("Failed to delete unit", error);
    }
  };

  const handleEditClick = (unit) => {
    fetchDrivers(); // Fetch drivers before editing a unit
    setModalAction("update");

    setUnit(unit);
    setShowModal(true);
  };

  const handleCancelClick = () => {
    setShowModal(false);
    setUnit({
      type: "",
      unitNumber: "",
      vin: "",
      year: "",
      make: "",
      model: "",
      users: [],
    });
  };

  const filteredUnits = units.filter((unit) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      unit.type.toLowerCase().includes(searchTermLower) ||
      unit.unitNumber.toLowerCase().includes(searchTermLower) ||
      unit.vin.toLowerCase().includes(searchTermLower) ||
      unit.year.toString().includes(searchTerm) ||
      unit.make.toLowerCase().includes(searchTermLower) ||
      unit.model.toLowerCase().includes(searchTermLower) ||
      (unit.users &&
        unit.users.some((user) =>
          user.label.toLowerCase().includes(searchTermLower)
        ))
    );
  });

  return (
    <div className="p-0 md:p-4 pb-16">
      <div className="flex flex-row md:flex-row justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search units..."
          value={searchTerm}
          onChange={handleSearch}
          className="border p-2 rounded md:mb-0"
        />
        <button
          className="bg-blue-500 text-white p-2 rounded flex items-center"
          onClick={async () => {
            await fetchDrivers();
            setShowModal(true);
            setModalAction("create");
          }}
        >
          <FaPlus className="mr-2" />
          Create Unit
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="hidden md:block">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border">Unit #</th>
                <th className="py-2 px-4 border">VIN</th>
                <th className="py-2 px-4 border">Make</th>
                <th className="py-2 px-4 border">Model</th>
                <th className="py-2 px-4 border">Year</th>
                <th className="py-2 px-4 border">Driver(s)</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUnits.length === 0 ? (
                <tr>
                  <td colSpan="7" className="py-2 px-4 border text-center">
                    No Units Created
                  </td>
                </tr>
              ) : (
                filteredUnits.map((unit, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border">
                      <Link
                        to={`/dashboard/unit/${unit._id}`}
                        className="flex items-center"
                      >
                        {unit.type === "Tractor" && (
                          <FaTruck className="mr-2" />
                        )}
                        {unit.type === "Trailer" && (
                          <FaTrailer className="mr-2" />
                        )}
                        {unit.unitNumber}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>{unit.vin}</Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.make}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.model}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.year}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      {unit.users && unit.users.length > 0 ? (
                        unit.users.map((user, userIndex) => (
                          <div key={userIndex}>{user.label}</div>
                        ))
                      ) : (
                        <div>No Users</div>
                      )}
                    </td>
                    <td className="py-2 px-4 border">
                      <button
                        className="bg-yellow-500 text-white p-1 rounded mr-2"
                        onClick={() => handleEditClick(unit)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="bg-red-500 text-white p-1 rounded"
                        onClick={() => handleDeleteClick(unit)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="block md:hidden">
          {!loadedUnits ? (
            <ul className="divide-y divide-gray-200">
              <li>
                <div className="bg-white p-4 flex justify-between items-center">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-300 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                      </div>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-300 rounded"></div>
                      <div className="h-2 bg-slate-300 rounded"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          ) : filteredUnits.length === 0 ? (
            <div className="text-center py-4">No Units Created</div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {filteredUnits.map((unit, index) => (
                <li key={index}>
                  <div className="bg-white p-4 flex justify-between items-center">
                    {/* Section 1: Icon and Unit Number */}
                    <Link
                      to={`/dashboard/unit/${unit._id}`}
                      className="flex flex-col items-center"
                    >
                      {unit.type === "Tractor" && (
                        <FaTruck className="text-gray-500 mb-1" />
                      )}
                      {unit.type === "Trailer" && (
                        <FaTrailer className="text-gray-500 mb-1" />
                      )}
                      <span className="text-blue-500 font-semibold">
                        {unit.unitNumber}
                      </span>
                    </Link>

                    {/* Section 2: VIN, Make, Model, Year */}
                    <Link
                      to={`/dashboard/unit/${unit._id}`}
                      className="text-sm text-gray-700"
                    >
                      <div className="mb-1">
                        <strong>
                          {unit.make} {unit.model} {unit.year}
                        </strong>
                      </div>
                      <div className="mb-1">{unit.vin}</div>
                    </Link>

                    {/* Section 3: Delete Buttons */}
                    <div className="flex flex-col items-end space-y-1">
                      <button
                        className="bg-red-600 hover:bg-green-700 text-white font-bold p-1 rounded-full"
                        onClick={() => handleDeleteClick(unit)}
                      >
                        <FaTrash className="text-base" />
                      </button>
                    </div>
                  </div>

                  <div className="bg-white px-4 pb-4 flex justify-between items-center">
                    <Link
                      to={`/dashboard/unit/${unit._id}`}
                      className="flex flex-col items-center"
                    >
                      Driver(s):
                    </Link>

                    <div className="text-sm text-gray-700">
                      {unit.users && unit.users.length > 0 ? (
                        <div className="flex flex-nowrap">
                          {unit.users.map((user, userIndex) => (
                            <span
                              key={userIndex}
                              className="bg-gray-100 text-gray-800 px-2 py-1 rounded mr-1 flex items-center"
                              onClick={() => {
                                if (/Mobi|Android/i.test(navigator.userAgent)) {
                                  window.location.href = `tel:${user.phone}`;
                                }
                              }}
                            >
                              {user.label}
                              <FaPhoneAlt className="ml-2" />
                            </span>
                          ))}
                        </div>
                      ) : (
                        <span>No Users</span>
                      )}
                    </div>
                    <div className="flex flex-col items-end space-y-1">
                      <button
                        className="bg-gray-400 hover:bg-blue-700 text-white font-bold p-1 rounded-full"
                        onClick={() => handleEditClick(unit)}
                      >
                        <FaEdit className="text-base" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {showModal && (
        <div
          class="relative z-10"
          aria-labelledby="create-unit"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-500/75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-blue-600 sm:mx-0 sm:size-10">
                      <svg
                        class="size-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 17v-6a1 1 0 011-1h4a1 1 0 011 1v6m-6 0h6m-6 0a2 2 0 11-4 0m10 0a2 2 0 11-4 0m-6 0h6m-6 0H5a2 2 0 01-2-2v-5a2 2 0 012-2h1.5a1 1 0 011 1v1h9v-1a1 1 0 011-1H19a2 2 0 012 2v5a2 2 0 01-2 2h-1.5m-6 0h6"
                        />
                      </svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        class="text-base font-semibold text-gray-900"
                        id="modal-title"
                      >
                        {modalAction === "create"
                          ? "Create Unit"
                          : "Update Unit"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="mb-4">
                    <label className="block mb-1">Type</label>
                    <select
                      name="type"
                      value={unit.type}
                      onChange={handleInputChange}
                      required
                      className="border p-2 rounded w-full"
                    >
                      <option value="">Select Type</option>
                      <option value="Tractor">Tractor</option>
                      <option value="Trailer">Trailer</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1">Make</label>
                    <input
                      type="text"
                      name="make"
                      value={unit.make}
                      onChange={handleInputChange}
                      required
                      className="border p-2 rounded w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1">Model</label>
                    <input
                      type="text"
                      name="model"
                      value={unit.model}
                      onChange={handleInputChange}
                      required
                      className="border p-2 rounded w-full"
                    />
                  </div>
                  <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                      <label className="block mb-1">Unit #</label>
                      <input
                        type="text"
                        name="unitNumber"
                        value={unit.unitNumber}
                        onChange={handleInputChange}
                        required
                        className="border p-2 rounded w-full"
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <label className="block mb-1">Year</label>
                      <input
                        type="text"
                        name="year"
                        value={unit.year}
                        onChange={handleInputChange}
                        required
                        className="border p-2 rounded w-full"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1">VIN</label>
                    <input
                      type="text"
                      name="vin"
                      value={unit.vin}
                      onChange={handleInputChange}
                      required
                      className="border p-2 rounded w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1">Drivers</label>
                    <Select
                      isMulti
                      name="drivers"
                      options={drivers}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={unit.users}
                      onChange={handleDriverChange}
                    />
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={
                      modalAction === "create"
                        ? handleCreateUnit
                        : handleUpdateUnit
                    }
                    class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    {modalAction === "create" ? "Create" : "Update"}
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
