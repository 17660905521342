import React, { useState, useEffect } from "react";
import axiosInstance from "./../../../../../../api/axiosInstance";
import { FaVideo, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

const Videos = ({ companyId, inspectionId, onNewVideoAdded }) => {
  const [videos, setVideos] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const { unitId } = useParams(); // Get unitId from the URL

  const handleVideoChange = (event) => {
    const files = Array.from(event.target.files);
    setVideos(files);
  };

  const handleUpload = async () => {
    setUploadError(null);
    setIsLoading(true);
    const formData = new FormData();

    if (videos.length === 0) {
      console.error("No videos to upload");
      setIsLoading(false);
      return;
    }

    videos.forEach((video) => {
      formData.append("files", video);
    });
    formData.append("type", "inspection");
    formData.append("company_id", companyId);
    formData.append("inspection_id", inspectionId);

    try {
      const response = await axiosInstance.post(
        "/inspection/video-inspection/" + unitId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            console.log("Upload progress:", percentCompleted);
          },
        }
      );

      setVideos([]);
      setUploadProgress(0);
      setUploadError(null);
      setIsLoading(false);

      onNewVideoAdded(response.data);
    } catch (err) {
      console.error("Client - Error uploading files:", err);
      setUploadError(
        "Error uploading video. Please check your network and try again."
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (videos.length > 0) {
      handleUpload();
    }
  }, [videos]);

  return (
    <div className="flex flex-col space-y-4">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="flex flex-col items-center">
            <div className="relative">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-2xl font-semibold text-white">
                  {uploadProgress}%
                </span>
              </div>
            </div>
            <h2 className="text-xl font-semibold text-white">Uploading...</h2>
            <p className="w-1/3 text-center text-white">Please wait</p>
          </div>
        </div>
      )}
      {/* <div className="flex justify-center items-center space-x-4">
        <FaVideo className="text-4xl text-green-500" />
        <div>
          <h1 className="text-2xl font-semibold">Video Inspection</h1>
          <p className="text-gray-500">Record video inspection for the unit</p>
        </div>
      </div> */}

      <input
        type="file"
        accept="video/*"
        capture="environment"
        onChange={handleVideoChange}
        className="hidden"
        id="video-record"
        disabled={isLoading}
      />
      <div className="flex flex-col justify-center items-center space-y-4 p-4">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
          <label
            htmlFor="video-record"
            className={`flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FaVideo className="mr-2" />
            Record video
          </label>
        </div>

        {videos.length > 0 && (
          <div className="flex flex-wrap justify-center items-center space-x-4">
            {videos.map((video, index) => (
              <div key={index} className="relative video-item m-2">
                <video
                  src={URL.createObjectURL(video)}
                  controls
                  className="w-24 h-24 object-cover rounded-md"
                />
                <div className="absolute top-0 right-0 flex space-x-1 mt-1 mr-1">
                  <button
                    className="p-1 bg-red-500 text-white rounded-full shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                    onClick={() =>
                      setVideos(videos.filter((_, i) => i !== index))
                    }
                    disabled={isLoading}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {videos.length > 0 && !uploadError && (
          <button
            onClick={handleUpload}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            disabled={isLoading || videos.length === 0}
          >
            Upload Videos
          </button>
        )}

        {uploadError && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="flex flex-col items-center">
              <span className="text-xl text-white font-bold mb-4 p-2">
                {uploadError}
              </span>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                onClick={handleUpload}
              >
                Retry
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Videos;
